import Router from 'Router';

function App() {
  return (
    <div className="App" style={{ height: '100%', width: '100%' }}>
      <Router />
    </div>
  );
}

export default App;
